<template>
  <div>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("buildingNumber")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.lots }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("gateCode")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.gate_code }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        md="6"
        sm="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
      >
        <v-row class="caption grey--text text--darken-1">{{
          $t("excavationAreaOnProperty")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.location }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("typeOfWork")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.work_type }}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="my-4 ml-1">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        md="6"
        sm="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
      >
        <v-row class="caption grey--text text--darken-1">{{
          $t("workDuration")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.duration }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("whoIsTheWork")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.done_for }}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("locatorInstructions")
        }}</v-row>
        <v-row
          v-for="item in excavationDetails.locatorInstructions"
          :key="item"
          class="grey--text text--darken-4 font-weight-medium body-2"
        >
          <div>
            {{ item }}
            <span
              v-if="checkIfPossible(item)"
              class="font-weight-normal grey--text text--darken-1 text-caption ml-2"
            >
              {{ $t("ifPossibleLabel") }}
            </span>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("directionsToAddress")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.directionToAddress }}
        </v-row>
      </v-col>
    </v-row>

    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("additionalComments")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.remarks }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("explosivesLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.blasting ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("workRailroadTrackLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.rr ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("directionalBoringLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.boring ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("premarksLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ formatString(excavationDetails.premarks) }}
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    excavationDetails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatString(ele) {
      return ele.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    },
    checkIfPossible(item) {
      return (
        item ===
          "Please don’t mark hard surfaces, such as driveway/sidewalks/hardscape" ||
        item === "Call prior to locating" ||
        item === "Mark with paint and flags" ||
        item === "Mark with flags only"
      );
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style lang="scss" scoped></style>
