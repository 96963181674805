var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("buildingNumber")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.lots) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("gateCode")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.gate_code) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
              attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
            },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("excavationAreaOnProperty")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.location) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("typeOfWork")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.work_type) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
              attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
            },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("workDuration")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.duration) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("whoIsTheWork")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.done_for) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("locatorInstructions")))]
              ),
              _vm._l(
                _vm.excavationDetails.locatorInstructions,
                function (item) {
                  return _c(
                    "v-row",
                    {
                      key: item,
                      staticClass:
                        "grey--text text--darken-4 font-weight-medium body-2",
                    },
                    [
                      _c("div", [
                        _vm._v(" " + _vm._s(item) + " "),
                        _vm.checkIfPossible(item)
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-normal grey--text text--darken-1 text-caption ml-2",
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("ifPossibleLabel")) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("directionsToAddress")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.excavationDetails.directionToAddress) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("additionalComments")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.remarks) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("explosivesLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.blasting ? "Yes" : "No") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("workRailroadTrackLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.excavationDetails.rr ? "Yes" : "No") + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("directionalBoringLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.boring ? "Yes" : "No") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("premarksLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.formatString(_vm.excavationDetails.premarks)) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }